import { format } from 'date-fns';
import { useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinearProgress, Loader, IconButton } from 'react-ui-kit-exante';

import { ORDERS } from '~/routes';

import { ReportInfoContext } from '../../ReportInfoProvider';
import { STATUS_FINISH } from '../../constants';

import {
  CenterLoader,
  DateWithProgressBar,
  Item,
  LinearProgressWrapper,
  Percent,
  ProgressBar,
  Statistic,
  StyledDate,
  Title,
  Value,
  Wrapper,
} from './HeadInfo.styled';

export const HeadInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dataReportInfo, isLoadingReportInfo } = useContext(ReportInfoContext);

  const date = dataReportInfo?.date;
  const dateObject = date && new Date(date);
  const stepOneName = dataReportInfo?.stepOneName;
  const stepTwoName = dataReportInfo?.stepTwoName;

  const progressValue =
    useMemo(() => {
      return Math.round(
        100 -
          ((Number(dataReportInfo?.waitingForStartCount) +
            Number(dataReportInfo?.inProcessCount)) /
            Number(dataReportInfo?.total)) *
            100,
      );
    }, [dataReportInfo]) || 0;

  const redirectBack = () =>
    location?.state?.activeReportsTab
      ? navigate(`/${ORDERS}`, {
          state: { activeReportsTab: true },
        })
      : navigate(`/${ORDERS}`);

  return (
    <Wrapper>
      {isLoadingReportInfo ? (
        <CenterLoader>
          <Loader size="l" />
        </CenterLoader>
      ) : (
        <>
          <DateWithProgressBar>
            <StyledDate>
              {dateObject ? format(dateObject, 'MM.dd.yyyy HH:mm:ss') : '––'}
            </StyledDate>
            <ProgressBar>
              <LinearProgressWrapper
                isFinish={dataReportInfo?.status === STATUS_FINISH}
              >
                <LinearProgress variant="determinate" value={progressValue} />
              </LinearProgressWrapper>
              <Percent>{`${progressValue}%`}</Percent>
            </ProgressBar>
            <IconButton
              iconSize={32}
              iconName="CloseIcon"
              onClick={redirectBack}
            />
          </DateWithProgressBar>
          <Statistic>
            {stepOneName && (
              <Item>
                <Value>{stepOneName}</Value>
                <Title>Action 1</Title>
              </Item>
            )}
            {stepTwoName && (
              <Item>
                <Value>{stepTwoName}</Value>
                <Title>Action 2</Title>
              </Item>
            )}
          </Statistic>
        </>
      )}
    </Wrapper>
  );
};
