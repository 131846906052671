import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import {
  Input,
  Select,
  InputDatePicker,
  Modal,
  ISelectOption,
} from 'react-ui-kit-exante';

import { orderManagementService } from '~/api';

import {
  ChooseField,
  SingleOrderActions,
  SingleOrderBaeOptions,
  SingleOrderGatewayOptions,
} from '../../constants';
import { usePostOrderSingle } from '../../hooks';

import { Field, Form } from './OrderFormStyled';

interface OrderFormPops {
  instrument?: string;
  placedTime: string | null;
  isBaeOrder: boolean | null;
  confirmModal: { isOpened: boolean; onClose: () => void };
  setIsDisabledConfirmedBtn: (isDisabledConfirmedBtn: boolean) => void;
}

export const OrderForm = ({
  placedTime,
  isBaeOrder,
  instrument,
  confirmModal,
  setIsDisabledConfirmedBtn,
}: OrderFormPops) => {
  const [chooseSelect, setChooseSelect] = useState('');
  const [comment, setComment] = useState('');
  const [currentFilledQuantity, setCurrentFilledQuantity] = useState('');
  const [fillQuantity, setFillQuantity] = useState('');
  const [fillPrice, setFillPrice] = useState('');
  const [exchangeTradeId, setExchangeTradeId] = useState('');
  const [fillTime, setFillTime] = useState<Date | null>(null);
  const [mICCodeOfExecutor, setMICCodeOfExecutor] = useState('');
  const [mICCodeOrXOFFCode, setMICCodeOrXOFFCode] = useState('');
  const [modificationId, setModificationId] = useState('');
  const [reason, setReason] = useState('');
  const [quantity, setQuantity] = useState('');
  const [limitPrice, setLimitPrice] = useState('');
  const [stopPrice, setStopPrice] = useState('');
  const [route, setRoute] = useState('');
  const [optionsRoute, setOptionsRoute] = useState<ISelectOption[]>([]);
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);

  const { postSingleOrder, isLoading } = usePostOrderSingle({
    route,
    reason,
    comment,
    fillPrice,
    fillTime,
    quantity,
    stopPrice,
    limitPrice,
    isBaeOrder,
    chooseSelect,
    fillQuantity,
    modificationId,
    exchangeTradeId,
    mICCodeOfExecutor,
    mICCodeOrXOFFCode,
    currentFilledQuantity,
    onCloseModal: confirmModal.onClose,
  });

  const {
    AddFill,
    MarkCancelled,
    RejectModification,
    EditOrder,
    PlaceOnRoute,
    Cancel,
    RejectOrder,
    PlaceOnInitialRoute,
    PlaceOnNextRoute,
  } = SingleOrderActions;

  const setValidationFillTime = () => {
    if (fillTime && placedTime) {
      return new Date(placedTime) > fillTime || fillTime > new Date();
    }
    return false;
  };

  const setDisabledStateConfirmedBtn = () => {
    if (chooseSelect === MarkCancelled) {
      return setIsDisabledConfirmedBtn(!comment);
    }
    if (chooseSelect === Cancel) {
      return setIsDisabledConfirmedBtn(false);
    }
    if (chooseSelect === PlaceOnInitialRoute) {
      return setIsDisabledConfirmedBtn(false);
    }
    if (chooseSelect === PlaceOnNextRoute) {
      return setIsDisabledConfirmedBtn(false);
    }
    if (chooseSelect === PlaceOnRoute) {
      return setIsDisabledConfirmedBtn(!route);
    }
    if (chooseSelect === AddFill) {
      return setIsDisabledConfirmedBtn(
        !comment ||
          !fillTime ||
          !fillPrice ||
          !fillQuantity ||
          !mICCodeOfExecutor ||
          !mICCodeOrXOFFCode ||
          !currentFilledQuantity ||
          setValidationFillTime(),
      );
    }
    if (chooseSelect === RejectModification) {
      return setIsDisabledConfirmedBtn(!reason || !modificationId);
    }
    if (chooseSelect === EditOrder) {
      return setIsDisabledConfirmedBtn(!quantity && !limitPrice && !stopPrice);
    }
    if (chooseSelect === RejectOrder) {
      return setIsDisabledConfirmedBtn(!reason);
    }

    return setIsDisabledConfirmedBtn(true);
  };

  const getRoutes = async () => {
    setIsLoadingRoutes(true);

    const routes =
      instrument && (await orderManagementService.getRoutes(instrument));

    const prepareData = routes?.data?.map((item: string) => {
      return { value: item, label: item };
    });

    if (prepareData) {
      setOptionsRoute(prepareData);
    }

    setIsLoadingRoutes(false);
  };

  useEffect(() => {
    if (instrument) {
      getRoutes();
    }
  }, []);

  useEffect(() => {
    setDisabledStateConfirmedBtn();
  }, [setDisabledStateConfirmedBtn]);

  const resetForm = () => {
    setRoute('');
    setReason('');
    setComment('');
    setQuantity('');
    setFillPrice('');
    setStopPrice('');
    setFillTime(null);
    setLimitPrice('');
    setFillQuantity('');
    setModificationId('');
    setExchangeTradeId('');
    setMICCodeOfExecutor('');
    setMICCodeOrXOFFCode('');
    setCurrentFilledQuantity('');
  };

  const onChangeChooseSelect = (e: ChangeEvent<HTMLInputElement>) => {
    resetForm();
    setChooseSelect(e.target.value);
  };

  const onChangeCommentHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    },
    [setComment],
  );

  const onChangeCurrentFilledQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentFilledQuantity(e.target.value);
    },
    [setCurrentFilledQuantity],
  );

  const onChangeFillQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFillQuantity(e.target.value);
    },
    [setFillQuantity],
  );

  const onChangeFillPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFillPrice(e.target.value);
    },
    [setFillPrice],
  );

  const onChangeExchangeTradeId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setExchangeTradeId(e.target.value);
    },
    [setExchangeTradeId],
  );

  const onChangeFillTime = useCallback(
    (value: Date | null) => {
      setFillTime(value);
    },
    [setFillTime],
  );

  const onChangeMICCodeOfExecutor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMICCodeOfExecutor(e.target.value);
    },
    [setMICCodeOfExecutor],
  );

  const onChangeMICCodeOrXOFFCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMICCodeOrXOFFCode(e.target.value);
    },
    [setMICCodeOrXOFFCode],
  );

  const onChangeModificationId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setModificationId(e.target.value);
    },
    [setModificationId],
  );

  const onChangeReason = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReason(e.target.value);
    },
    [setReason],
  );

  const onChangeQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuantity(e.target.value);
    },
    [setQuantity],
  );

  const onChangeLimitPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLimitPrice(e.target.value);
    },
    [setLimitPrice],
  );

  const onChangeStopPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStopPrice(e.target.value);
    },
    [setStopPrice],
  );

  const onChangeRoute = useCallback(
    (value: string) => {
      setRoute(value);
    },
    [setRoute],
  );

  const renderFields = () => {
    if (chooseSelect === AddFill) {
      return (
        <>
          <Field>
            <Input
              fullWidth
              value={comment}
              placeholder="Comment"
              onChange={onChangeCommentHandler}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={currentFilledQuantity}
              placeholder="Сurrent Filled Quantity"
              onChange={onChangeCurrentFilledQuantity}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={fillQuantity}
              placeholder="Fill Quantity"
              onChange={onChangeFillQuantity}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={fillPrice}
              placeholder="Fill Price"
              onChange={onChangeFillPrice}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={exchangeTradeId}
              placeholder="Exchange TradeId"
              onChange={onChangeExchangeTradeId}
            />
          </Field>
          <Field>
            <InputDatePicker
              showTimeInput
              selected={fillTime}
              onChange={onChangeFillTime}
              inputProps={{
                fullWidth: true,
                label: 'Fill Time',
                error: setValidationFillTime(),
                message: setValidationFillTime()
                  ? 'Fill time is less placed time or bigger then the current time'
                  : '',
              }}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={mICCodeOfExecutor}
              placeholder="MIC Code Of Executor"
              onChange={onChangeMICCodeOfExecutor}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={mICCodeOrXOFFCode}
              placeholder="MIC Code Or X OFFCode"
              onChange={onChangeMICCodeOrXOFFCode}
            />
          </Field>
        </>
      );
    }

    if (chooseSelect === MarkCancelled) {
      return (
        <Field>
          <Input
            fullWidth
            value={comment}
            placeholder="Comment"
            onChange={onChangeCommentHandler}
          />
        </Field>
      );
    }

    if (chooseSelect === RejectModification) {
      return (
        <>
          <Field>
            <Input
              fullWidth
              value={modificationId}
              placeholder="ModificationId"
              onChange={onChangeModificationId}
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={reason}
              placeholder="Reason"
              onChange={onChangeReason}
            />
          </Field>
        </>
      );
    }

    if (chooseSelect === EditOrder) {
      return (
        <>
          <Field>
            <Input
              fullWidth
              value={quantity}
              onChange={onChangeQuantity}
              placeholder="Quantity"
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={limitPrice}
              onChange={onChangeLimitPrice}
              placeholder="Limit Price"
            />
          </Field>
          <Field>
            <Input
              fullWidth
              value={stopPrice}
              onChange={onChangeStopPrice}
              placeholder="Stop Price"
            />
          </Field>
        </>
      );
    }

    if (chooseSelect === RejectOrder) {
      return (
        <Field>
          <Input
            fullWidth
            value={reason}
            onChange={onChangeReason}
            placeholder="Reason"
          />
        </Field>
      );
    }

    if (chooseSelect === PlaceOnRoute) {
      return (
        <Field>
          <Select
            fullWidth
            value={route}
            placeholder="Route"
            options={optionsRoute}
            disabled={isLoadingRoutes}
            onChange={(e) => {
              onChangeRoute(e.target.value);
            }}
          />
        </Field>
      );
    }

    return null;
  };

  return (
    <div>
      <Select
        sx={ChooseField}
        placeholder="Choose Operation"
        value={chooseSelect}
        options={isBaeOrder ? SingleOrderBaeOptions : SingleOrderGatewayOptions}
        onChange={onChangeChooseSelect}
      />
      <Form>{chooseSelect && renderFields()}</Form>
      <Modal
        isOpened={confirmModal.isOpened}
        onClose={() => {
          confirmModal.onClose();
        }}
        title="Are you sure that want to update an order?"
        confirmButton={{
          handleConfirm: postSingleOrder,
          confirmButtonIsDisabled: isLoading,
        }}
        keepMounted={false}
      >
        {/* need a children/ */}
      </Modal>
    </div>
  );
};
