export const DISPLAYED_COLUMNS_KEYS = [
  'date',
  'stepOneFailureCount',
  'stepTwoFailureCount',
  'successCount',
  'stepTwoName',
  'username',
];

export const DEFAULT_SORTING = [{ id: 'date', desc: true }];
