import { styled } from 'react-ui-kit-exante';

type TCounterProps = {
  isActive: boolean;
};

export const Counter = styled('span')<TCounterProps>(({ theme, isActive }) => ({
  padding: '1px 10px',
  borderRadius: '20px',
  border: `2px solid ${
    isActive ? theme?.color?.typo?.primary : theme?.color?.typo?.action
  }`,
  '&:hover': {
    border: `2px solid ${theme?.color.typo.promo}`,
  },
}));
