import { Counter } from './LabelTab.styled';

interface LabelTabProps {
  text: string;
  numberTab: number;
  activeTab: number;
  value: number | string | undefined;
}
export const LabelTab = ({
  text,
  value,
  numberTab,
  activeTab,
}: LabelTabProps) => (
  <span>
    {`${text} `}
    <Counter isActive={activeTab === numberTab}>
      {typeof value === 'number' ? value : '–'}
    </Counter>
  </span>
);
