import { styled, Panel } from 'react-ui-kit-exante';

export const Actions = styled('div')({ display: 'flex' });

export const Separator = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme?.color?.input?.border}`,
  margin: '14px -24px 38px -24px',
}));

export const InfoBlock = styled('section')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const PanelStyled = styled(Panel)({
  height: '80vh',
});
