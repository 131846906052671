import { useLayoutEffect, useState } from 'react';

import { applicationService } from 'api';

import { DarkModeButton, isDarkMode } from '../DarkModeButton';

import { Title, Version, Wrapper } from './Header.styled';

export const Header = () => {
  const [version, setVersion] = useState('');

  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      applicationService.getAppVersion().then(setVersion);
    }
  }, []);

  const onClickDarkModeBtn = () => {
    window.location.reload();
    localStorage.setItem('dark-mode', isDarkMode ? 'false' : 'true');
  };

  return (
    <Wrapper isDarkMode={isDarkMode}>
      <span>
        <Title>Order Management</Title>
        <Version>{version}</Version>
      </span>
      <DarkModeButton onClick={onClickDarkModeBtn} />
    </Wrapper>
  );
};
