const isInsideRun = () =>
  window.runUIhistoryContainer && window.ORDER_MANAGEMENT_UI;

const defaultURLs = {
  nodeBack: 'node-back-stage.exante.eu',
  wfe: 'wfe-stage.exante.eu',
  auditLog: 'auditlog-stage.exante.eu',
  swagger: 'backoffice-stage.exante.eu',
};

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] WEB-BO application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseBackOfficeUrl = (fallback = defaultURLs.nodeBack) => {
  if (isInsideRun()) {
    const url = window.ORDER_MANAGEMENT_UI?.api_urls?.BO;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseBackOfficeUrl',
          'window.WEB_BO_UI.api_urls.BO',
          'process.env.REACT_APP_API_BO',
        ),
      );
      return process.env.REACT_APP_API_BO || fallback;
    }

    return url;
  }

  return process.env.REACT_APP_API_BO || fallback;
};
