import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, IconButton, Button, useModal } from 'react-ui-kit-exante';

import { BAE } from '~/pages/OrderManagement/constants';
import { ORDERS } from '~/routes';

import {
  Actions,
  InfoBlock,
  PanelStyled,
  Separator,
} from './SingleOrder.styled';
import { OrderForm, InfoCard } from './components';
import { useOrderData } from './hooks';

export const SingleOrder = () => {
  const confirmModal = useModal();
  const navigate = useNavigate();
  const [isDisabledConfirmedBtn, setIsDisabledConfirmedBtn] = useState(true);
  const { dataOrder, isLoading, firstInfoCard, secondInfoCard, thirdInfoCard } =
    useOrderData();

  const isBaeOrder = dataOrder?.module?.includes(BAE) || null;
  const placedTime =
    (firstInfoCard.length > 0 && (firstInfoCard[1].value as string)) || null;

  return (
    <div>
      {isLoading ? (
        <Loader size="l" isCentered />
      ) : (
        <PanelStyled
          title={dataOrder?.instrument || 'Not found'}
          action={
            <Actions>
              <Button
                size="small"
                onClick={confirmModal.onOpen}
                style={{ marginRight: '16px' }}
                disabled={isDisabledConfirmedBtn}
              >
                Confirm
              </Button>
              <IconButton
                iconSize={32}
                iconName="CloseIcon"
                onClick={() => navigate(`/${ORDERS}`)}
              />
            </Actions>
          }
        >
          <OrderForm
            placedTime={placedTime}
            isBaeOrder={isBaeOrder}
            confirmModal={confirmModal}
            instrument={dataOrder?.instrument}
            setIsDisabledConfirmedBtn={setIsDisabledConfirmedBtn}
          />
          <Separator />
          <InfoBlock>
            <InfoCard data={firstInfoCard} />
            <InfoCard data={secondInfoCard} />
            <InfoCard data={thirdInfoCard} />
          </InfoBlock>
        </PanelStyled>
      )}
    </div>
  );
};
