export enum NameTabs {
  NotСancelled,
  NotPlaced,
  Fulfilled,
}

export const COLUMNS = [
  {
    Header: 'Order Id',
    accessor: 'orderId',
    disableSortBy: true,
  },
  {
    Header: 'Updated',
    accessor: 'lastUpdate',
    formatting: 'dateTime',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'orderStatus',
    disableSortBy: true,
  },
  {
    Header: 'Instrument',
    accessor: 'instrument',
    disableSortBy: true,
  },
  {
    Header: 'New Order Id',
    accessor: 'newOrderId',
    disableSortBy: true,
  },
  {
    Header: 'Account Name',
    accessor: 'accountName',
    disableSortBy: true,
  },
  {
    Header: 'Gateway Name',
    accessor: 'gatewayName',
    disableSortBy: true,
  },
  {
    Header: 'Provider Name',
    accessor: 'providerName',
    disableSortBy: true,
  },
];

export const STATUS_FINISH = 'FINISH';
