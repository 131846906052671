import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

export const DISPLAYED_COLUMNS_KEYS = [
  'status',
  'module',
  'lastUpdate',
  'type',
  'instrument',
  'side',
  'quantity',
  'originalLimitPrice',
  'fills',
  'averagePrice',
];

export const DEFAULT_SORTING = [{ id: 'lastUpdate', desc: false }];

export enum ActionNames {
  Reset = '—',
  CancelViaBrokerProtocol = 'Cancel via Broker Protocol',
  CancelViaJMX = 'Cancel via JMX',
  ReplaceOnNextRoute = 'Replace on Next Route',
  ReplaceOnInitialRoute = 'Replace on Initial Route',
  PlaceViaBrokerProtocol = 'Place via Broker Protocol',
  ReplaceOnRoute = 'Replace on Route',
}

export enum MethodNames {
  PlaceOnNextRoute = 'place-on-next-route',
  PlaceOnInitialRoute = 'place-on-initial-route',
  PlaceOnRoute = 'place-on-route',
  MarkCancelled = 'mark-cancelled',
  Replace = 'replace',
  Cancel = 'cancel',
}

export enum Statuses {
  Pending = 'PENDING',
  Working = 'WORKING',
  Processed = 'PROCESSED',
  Registered = 'REGISTERED',
  Failed = 'FAILED',
  Placing = 'PLACING',
  Canceled = 'CANCELED',
  Requested = 'REQUESTED',
}
export enum TypesOrder {
  Broker = 'BROKER',
  Internal = 'INTERNAL',
}

export enum ExtraDestinationRoute {
  Bae = 'BAE',
  Same = 'SAME',
}

export const extraOptionsDestinationRoute = [
  { value: ExtraDestinationRoute.Bae, label: ExtraDestinationRoute.Bae },
  { value: ExtraDestinationRoute.Same, label: ExtraDestinationRoute.Same },
];

export const optionsFirstSelect = [
  {
    value: ActionNames.CancelViaBrokerProtocol,
    label: ActionNames.CancelViaBrokerProtocol,
  },
  { value: ActionNames.CancelViaJMX, label: ActionNames.CancelViaJMX },
  {
    value: ActionNames.ReplaceOnNextRoute,
    label: ActionNames.ReplaceOnNextRoute,
  },
  {
    value: ActionNames.ReplaceOnInitialRoute,
    label: ActionNames.ReplaceOnInitialRoute,
  },
  {
    value: ActionNames.ReplaceOnRoute,
    label: ActionNames.ReplaceOnRoute,
  },
];

export const disabledOptionsFirstSelect = [
  {
    value: ActionNames.CancelViaBrokerProtocol,
    label: ActionNames.CancelViaBrokerProtocol,
  },
  {
    value: ActionNames.CancelViaJMX,
    label: ActionNames.CancelViaJMX,
  },
  {
    value: ActionNames.ReplaceOnNextRoute,
    label: ActionNames.ReplaceOnNextRoute,
    disabled: true,
  },
  {
    value: ActionNames.ReplaceOnInitialRoute,
    label: ActionNames.ReplaceOnInitialRoute,
    disabled: true,
  },
  {
    value: ActionNames.ReplaceOnRoute,
    label: ActionNames.ReplaceOnRoute,
    disabled: true,
  },
];

export const optionsSecondSelect = [
  { value: 'Place via Broker Protocol', label: 'Place via Broker Protocol' },
  { value: DEFAULT_EMPTY_PLACEHOLDER, label: DEFAULT_EMPTY_PLACEHOLDER },
];

export const staticOptionsDestinationGateway = [
  { value: 'Initial Gate', label: 'Initial Gate' },
  { value: 'Automation Engine', label: 'Automation Engine' },
];

export const BAE = 'broker-automation-engine';
