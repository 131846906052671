import { difference } from 'lodash';
import { useContext, useEffect, useState, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ORDERS } from '~/routes';
import { getTableId } from '~/utils';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import { DEFAULT_SORTING, DISPLAYED_COLUMNS_KEYS } from '../../constants';
import { useColumns, useOrdersTableData } from '../../hooks';

import { TableContainer } from './styled';

interface ActionTableProps {
  resetForm: () => void;
}

const ActionTable = ({ resetForm }: ActionTableProps) => {
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false);

  const {
    handleSelectOrder,
    selectedOrder,
    dataModules,
    setIsSelectAll,
    isSelectAll,
    setDataActionsTable,
  } = useContext(OrderManagementDataContext);

  const resetSettings = () => {
    resetForm();
    setIsSelectAll(false);
    handleSelectOrder([]);
  };

  const {
    data,
    isLoading,
    setFilter,
    removeFilter,
    filteringProps,
    additionalActions,
    serverPaginationProps,
  } = useOrdersTableData(refetch, setRefetch, resetSettings);

  const filters = filteringProps?.filters;
  const filterByModule = (filters?.module as []) || [];
  const hasActiveFilters =
    filters?.account ||
    filters?.brokerAccountName ||
    filters?.brokerGatewayName ||
    filters?.brokerProviderName ||
    filters?.brokerUrl ||
    filters?.id ||
    filters?.instrument ||
    filterByModule.length > 0;

  const columns = useColumns({
    onFilter: (column, value) => {
      resetSettings();
      setFilter(column, value);
    },
    onRemove: (column) => {
      resetSettings();
      removeFilter(column);
    },
    optionsModule: difference(dataModules, filterByModule),
  });

  useEffect(() => {
    if (data) {
      setDataActionsTable(data);
    }
  }, [data, setDataActionsTable]);

  const handleRowClick = useCallback(
    ({ id }: { id?: string }) => {
      if (id) {
        navigate(`/${ORDERS}/${id}`);
      }
    },
    [navigate],
  );

  return (
    <TableContainer
      hasFilters
      isFlexLayout
      showTableInfo
      hasPagination
      pageSize={20}
      isPinnedHeader
      columns={columns}
      isLoading={isLoading}
      data={data?.data || []}
      saveViewParamsAfterLeave
      handleRowClick={handleRowClick}
      filteringProps={filteringProps}
      defaultSortBy={DEFAULT_SORTING}
      additionalActions={additionalActions}
      bulkActions={{
        tooltipAllSelect: !hasActiveFilters
          ? 'Please, filter orders to enable select all'
          : '',
        disabledAllSelect: !hasActiveFilters,
        isSelectAll,
        setIsSelectAll,
        selectedRows: selectedOrder,
        setSelectedRows: handleSelectOrder,
      }}
      tableId={getTableId('OrdersTable')}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      serverPaginationProps={serverPaginationProps}
    />
  );
};

export default memo(ActionTable);
