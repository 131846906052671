import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Tabs,
  Tab,
  TabPanel,
  Loader,
  ISelectOption,
} from 'react-ui-kit-exante';

import { orderManagementService } from 'api';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import { extraOptionsDestinationRoute } from '../../constants';
import ActionTable from '../ActionsTable';
import { ReportsTables } from '../ReportsTable';
import { SettingsForm } from '../SettingsForm';

import { Wrapper, WrapperLoader } from './OrderManagementTables.styled';

export const OrderManagementTables = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    dataModules,
    username,
    selectedOrder,
    isSelectAll,
    dataActionsTable,
  } = useContext(OrderManagementDataContext);
  const [activeTab, setActiveTab] = useState(
    location?.state?.activeReportsTab ? 1 : 0,
  );
  const [firstSelect, setFirstSelect] = useState('');
  const [secondSelect, setSecondSelect] = useState(DEFAULT_EMPTY_PLACEHOLDER);
  const [destinationRoute, setDestinationRoute] = useState('');
  const [brokerUrl, setBrokerUrl] = useState('');
  const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);
  const [optionsDestinationRoute, setOptionsDestinationRoute] = useState<
    ISelectOption[]
  >([]);
  const [optionsRoute, setOptionsRoute] = useState<ISelectOption[]>([]);
  const [baeRoute, setBaeRoute] = useState('');
  const [jmxComment, setJmxComment] = useState('');

  const getRoutes = async (instrument: string) => {
    setIsLoadingRoutes(true);

    const routes = await orderManagementService.getRoutes(instrument);

    const prepareData = routes?.data?.map((item: string) => {
      return { value: item, label: item };
    });

    if (prepareData) {
      setOptionsRoute(prepareData);
      setOptionsDestinationRoute([
        ...extraOptionsDestinationRoute,
        ...prepareData,
      ]);
    }

    setIsLoadingRoutes(false);
  };

  const definesOptionsRoutes = () => {
    if (selectedOrder.length > 0 || isSelectAll) {
      const isSameInstrument = selectedOrder.every(
        (item) =>
          selectedOrder[0]?.values?.instrument === item.values.instrument,
      );

      const instrument =
        selectedOrder[0]?.values?.instrument ||
        dataActionsTable?.data[0]?.instrument ||
        '';

      if ((isSameInstrument || selectedOrder.length === 1) && !isSelectAll) {
        getRoutes(instrument);
      } else {
        setOptionsRoute([]);
        setOptionsDestinationRoute(extraOptionsDestinationRoute);
      }
    }
  };

  const resetForm = () => {
    setBrokerUrl('');
    setFirstSelect('');
    setDestinationRoute('');
    setSecondSelect(DEFAULT_EMPTY_PLACEHOLDER);
  };

  useEffect(() => {
    definesOptionsRoutes();
  }, [selectedOrder, isSelectAll, dataActionsTable]);

  return dataModules.length > 0 ? (
    <>
      <Wrapper>
        <Tabs
          value={activeTab}
          onChange={(e, value) => {
            setActiveTab(value);
            navigate('/om/orders');
          }}
        >
          <Tab label="Actions" />
          <Tab label="Reports" />
        </Tabs>
      </Wrapper>
      <TabPanel value={activeTab} index={0}>
        <SettingsForm
          baeRoute={baeRoute}
          setBaeRoute={setBaeRoute}
          jmxComment={jmxComment}
          setJmxComment={setJmxComment}
          brokerUrl={brokerUrl}
          firstSelect={firstSelect}
          optionsRoute={optionsRoute}
          secondSelect={secondSelect}
          setBrokerUrl={setBrokerUrl}
          setFirstSelect={setFirstSelect}
          isLoadingRoutes={isLoadingRoutes}
          setSecondSelect={setSecondSelect}
          destinationRoute={destinationRoute}
          setDestinationRoute={setDestinationRoute}
          optionsDestinationRoute={optionsDestinationRoute}
        />
        <ActionTable resetForm={resetForm} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {username ? (
          <ReportsTables />
        ) : (
          <WrapperLoader>
            <Loader isCentered size="l" />
          </WrapperLoader>
        )}
      </TabPanel>
    </>
  ) : (
    <Loader isCentered size="l" />
  );
};
