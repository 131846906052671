import { RefreshIcon } from '~/assets/images/icons';

import { RefreshButtonStyled } from './RefreshButton.styled';

interface RefreshButtonProps {
  onRefresh: () => void;
  disabled?: boolean;
}

export const RefreshButton = ({ onRefresh, disabled }: RefreshButtonProps) => {
  return (
    <RefreshButtonStyled
      disabled={disabled}
      title="Refresh"
      onClick={onRefresh}
    >
      <RefreshIcon />
    </RefreshButtonStyled>
  );
};
