import { styled } from 'react-ui-kit-exante';

export const Wrapper = styled('header')(
  ({ isDarkMode }: { isDarkMode: boolean }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 24px',
    backgroundColor: !isDarkMode ? '#141919' : '#409f6b',
  }),
);

export const Title = styled('div')(() => ({
  fontSize: '18px',
  color: 'white',
  marginBottom: '10px',
}));

export const Version = styled('div')(() => ({
  color: 'white',
  fontSize: '12px',
  fontWeight: '500',
}));
