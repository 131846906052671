import { useState, useEffect } from 'react';

import { orderManagementService } from 'api';

export function useModulesData() {
  const [dataModules, setDataModules] = useState([]);

  const getModules = async () => {
    const response = await orderManagementService.getModules();
    const data = response?.data;

    if (data) {
      setDataModules(data.map(({ name }: { name: string }) => name));
    }
  };

  useEffect(() => {
    getModules();
  }, []);

  return { dataModules, defaultModule: dataModules[0] || '' };
}
