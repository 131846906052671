import { OrderManagementProvider } from './OrderManagementProvider';
import { OrderManagementTables } from './components/OrderManagementTables';

export const OrderManagement = () => {
  return (
    <OrderManagementProvider>
      <OrderManagementTables />
    </OrderManagementProvider>
  );
};
