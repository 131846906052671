import { ReactNode } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { Notifications } from '~/constants';
import { Logger } from '~/types/logger';

import { AccessRightsRepository } from './accessRights.repository';

export class AccessRightsService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  public async getCurrentUserAccessRights() {
    try {
      const { data } =
        await AccessRightsRepository.fetchCurrentUserAccessRights();

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }
}
