import { useContext, useMemo, ChangeEvent, useCallback } from 'react';
import {
  Button,
  Select,
  Input,
  Modal,
  Tooltip,
  useModal,
} from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import {
  ActionNames,
  optionsFirstSelect,
  optionsSecondSelect,
  disabledOptionsFirstSelect,
  ExtraDestinationRoute,
} from '../../constants';
import { usePostOrders } from '../../hooks';

import {
  Fields,
  Wrapper,
  Field,
  AllFields,
  ExtraField,
} from './SettingsForm.styled';
import { SettingsFormProps } from './types';

export const SettingsForm = ({
  firstSelect,
  setFirstSelect,
  secondSelect,
  setSecondSelect,
  brokerUrl,
  setBrokerUrl,
  destinationRoute,
  setDestinationRoute,
  optionsDestinationRoute,
  isLoadingRoutes,
  baeRoute,
  setBaeRoute,
  jmxComment,
  setJmxComment,
  optionsRoute,
}: SettingsFormProps) => {
  const confirmModal = useModal();
  const { selectedOrder, isBae, isSelectAll, dataActionsTable, setRequestId } =
    useContext(OrderManagementDataContext);
  const { postOrders } = usePostOrders({
    baeRoute,
    brokerUrl,
    firstSelect,
    destinationRoute,
    jmxComment,
    secondSelect,
  });

  const countOrders = selectedOrder.length;
  const totalOrders = dataActionsTable?.pagination?.total || 0;
  const disabledFirstSelect = isSelectAll ? !isSelectAll : !countOrders;
  const isDestinationRouteBae = destinationRoute === ExtraDestinationRoute.Bae;
  const isReplaceOnRouteSelected = firstSelect === ActionNames.ReplaceOnRoute;
  const isCancelViaJMXSelected = firstSelect === ActionNames.CancelViaJMX;

  const numbersSelectedOrder = useMemo(
    () => (isSelectAll ? totalOrders - countOrders : countOrders),
    [isSelectAll, totalOrders, countOrders],
  );

  const disabledSecondSelect =
    firstSelect === '' || firstSelect !== ActionNames.CancelViaBrokerProtocol;
  const disabledDestinationRoute =
    isLoadingRoutes ||
    disabledFirstSelect ||
    secondSelect === DEFAULT_EMPTY_PLACEHOLDER;

  const disabledConfirm = () => {
    if (firstSelect === ActionNames.ReplaceOnRoute && baeRoute) {
      return false;
    }
    if (
      firstSelect &&
      firstSelect !== ActionNames.ReplaceOnRoute &&
      secondSelect === DEFAULT_EMPTY_PLACEHOLDER
    ) {
      return false;
    }
    if (destinationRoute) {
      if (destinationRoute === ExtraDestinationRoute.Same) {
        return false;
      }
      if (destinationRoute === ExtraDestinationRoute.Bae && brokerUrl) {
        return false;
      }
      if (
        destinationRoute !== ExtraDestinationRoute.Bae &&
        destinationRoute !== ExtraDestinationRoute.Same
      ) {
        return false;
      }
    }

    return true;
  };

  const resetExtraFields = () => {
    setBrokerUrl('');
  };

  const handleChangeFirstSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstSelect(e.target.value);
    setSecondSelect(DEFAULT_EMPTY_PLACEHOLDER);
    setDestinationRoute('');
    setBaeRoute('');
  };

  const handleChangeSecondSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondSelect(e.target.value);
    setDestinationRoute('');
    resetExtraFields();
  };

  const onChangeBrokerUrlHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBrokerUrl(e.target.value);
    },
    [setBrokerUrl],
  );

  const handleChangeDestinationRoute = (e: ChangeEvent<HTMLInputElement>) => {
    resetExtraFields();
    setDestinationRoute(e.target.value);
  };

  const handleChangeBaeRoute = (e: ChangeEvent<HTMLInputElement>) => {
    setBaeRoute(e.target.value);
  };

  const handleChangeJMXComment = (e: ChangeEvent<HTMLInputElement>) => {
    setJmxComment(e.target.value);
  };

  const handleModalClose = () => {
    setRequestId('');
    confirmModal.onClose();
  };

  return (
    <Wrapper>
      <AllFields>
        <Fields>
          <Tooltip
            style={{ width: '32%', marginRight: '24px' }}
            placement="bottom-start"
            title="Please select orders in the table below"
            disableHoverListener={countOrders > 0 || isSelectAll}
          >
            <div style={{ width: '100%' }}>
              <Select
                fullWidth
                placeholder="Choose"
                value={firstSelect}
                options={
                  isBae ? optionsFirstSelect : disabledOptionsFirstSelect
                }
                onChange={handleChangeFirstSelect}
                disabled={disabledFirstSelect}
              />
            </div>
          </Tooltip>
          <Field>
            <Select
              fullWidth
              placeholder="Choose"
              value={secondSelect}
              options={optionsSecondSelect}
              onChange={handleChangeSecondSelect}
              disabled={disabledSecondSelect || disabledFirstSelect}
            />
          </Field>
          <Field>
            <Select
              fullWidth
              value={destinationRoute}
              placeholder="Destination Route"
              options={optionsDestinationRoute}
              disabled={disabledDestinationRoute}
              onChange={handleChangeDestinationRoute}
            />
          </Field>
        </Fields>
        <Fields>
          {isDestinationRouteBae && (
            <ExtraField>
              <Input
                fullWidth
                value={brokerUrl}
                placeholder="Broker Url"
                onChange={onChangeBrokerUrlHandler}
              />
            </ExtraField>
          )}
        </Fields>
        <Fields>
          {isReplaceOnRouteSelected && (
            <ExtraField>
              <Select
                fullWidth
                value={baeRoute}
                placeholder="Route"
                options={optionsRoute}
                disabled={isLoadingRoutes}
                onChange={handleChangeBaeRoute}
              />
            </ExtraField>
          )}
        </Fields>
        <Fields>
          {isCancelViaJMXSelected && (
            <ExtraField>
              <Input
                fullWidth
                value={jmxComment}
                placeholder="Comment"
                onChange={handleChangeJMXComment}
              />
            </ExtraField>
          )}
        </Fields>
      </AllFields>
      <Button onClick={confirmModal.onOpen} disabled={disabledConfirm()}>
        Confirm
      </Button>
      <Modal
        isOpened={confirmModal.isOpened}
        onClose={handleModalClose}
        title={`Are you sure that want to update ${numbersSelectedOrder} orders?`}
        confirmButton={{
          handleConfirm: postOrders,
        }}
        keepMounted={false}
      >
        <div />
      </Modal>
    </Wrapper>
  );
};
