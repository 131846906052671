import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { orderManagementService } from 'api';
import { ReportInfoContext } from '~/pages/ReportInfo/ReportInfoProvider';

import { NameTabs, STATUS_FINISH } from '../constants';

export function useReportTables(activeTable: number | null) {
  const { id } = useParams();
  const { dataReportInfo } = useContext(ReportInfoContext);
  const [dataTable, setDataTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const status = dataReportInfo?.status;
  const stepTwoName = dataReportInfo?.stepTwoName;

  const getOrderReportSuccessList = async (actionId: string) => {
    setIsLoading(true);
    const response = await orderManagementService.getOrderReportSuccessList(
      actionId,
    );
    setIsLoading(false);

    return setDataTable(response?.data);
  };

  const getOrderReportStepOneList = async (actionId: string) => {
    setIsLoading(true);
    const response = await orderManagementService.getOrderReportStepOneList(
      actionId,
    );
    setIsLoading(false);

    return setDataTable(response?.data);
  };

  const getOrderReportStepTwoList = async (actionId: string) => {
    setIsLoading(true);
    const response = await orderManagementService.getOrderReportStepTwoList(
      actionId,
    );
    setIsLoading(false);

    return setDataTable(response?.data);
  };

  useEffect(() => {
    if (id && status === STATUS_FINISH) {
      if (activeTable === NameTabs.NotСancelled) {
        getOrderReportStepOneList(id);
      }
      if (stepTwoName && activeTable === NameTabs.NotPlaced) {
        getOrderReportStepTwoList(id);
      }
      if (
        activeTable === NameTabs.Fulfilled ||
        (!stepTwoName && activeTable === NameTabs.NotPlaced)
      ) {
        getOrderReportSuccessList(id);
      }
    }
  }, [activeTable, status, id]);

  return { dataTable, isLoading };
}
