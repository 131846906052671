import {
  TetrominoFour,
  TetrominoOne,
  Tetrominos,
  TetrominoThree,
  TetrominoTwo,
} from './TetrisLoader.styled';

export const TetrisLoader = () => (
  <Tetrominos>
    <TetrominoOne />
    <TetrominoTwo />
    <TetrominoThree />
    <TetrominoFour />
  </Tetrominos>
);
