import { Logger } from '../types/logger';

import { AccessRightsService } from './accessRights';
import { ApplicationService } from './application';
import { OrderManagementService } from './orderManagement';

let orderManagementService: OrderManagementService;
let applicationService: ApplicationService;

let accessRightsService: AccessRightsService;

export function initResources(logger: Logger) {
  applicationService = new ApplicationService(logger);
  orderManagementService = new OrderManagementService(logger);
  accessRightsService = new AccessRightsService(logger);
}

export { applicationService, orderManagementService, accessRightsService };
