import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { orderManagementService } from '~/api';
import { StatusCell } from '~/components/StatusCell/StatusCell';
import { DEFAULT_POOLING_INTERVAL_DATA, EN_DASH } from '~/constants';
import { useInterval } from '~/hooks';
import { sumQuantity } from '~/pages/OrderManagement/hooks/useColumns/useColumns';
import { OptionProps } from '~/types/common';
import { OrderProps } from '~/types/orderManagement';
import { formatDate } from '~/utils/formatters/formatDate';

export function useOrderData() {
  const { id } = useParams();
  const [dataOrder, setDataOrder] = useState<OrderProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [firstInfoCard, setFirstInfoCard] = useState<OptionProps[]>([]);
  const [secondInfoCard, setSecondInfoCard] = useState<OptionProps[]>([]);
  const [thirdInfoCard, setThirdInfoCard] = useState<OptionProps[]>([]);

  const getOrder = async (isPolling?: boolean) => {
    if (!isPolling) {
      setIsLoading(true);
    }
    const response = await orderManagementService.getOrder(id as string);

    setDataOrder(response);

    if (!isPolling) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  useInterval(() => {
    if (dataOrder) {
      getOrder(true);
    }
  }, DEFAULT_POOLING_INTERVAL_DATA);

  useEffect(() => {
    if (dataOrder) {
      const {
        status,
        lastUpdate,
        type,
        instrument,
        side,
        quantity,
        averagePrice,
        account,
        brokerUrl,
        module,
        brokerAccount,
        originalLimitPrice,
        fills,
        placeTime,
      } = dataOrder;

      setFirstInfoCard([
        { label: 'Status', value: <StatusCell type={status?.type} /> },
        {
          label: 'Placed',
          value: (placeTime && formatDate(new Date(placeTime))) || EN_DASH,
        },
        {
          label: 'Updated',
          value:
            (lastUpdate?.localTime &&
              formatDate(new Date(lastUpdate?.localTime))) ||
            EN_DASH,
        },
        { label: 'Type', value: type?.type },
        { label: 'Instrument', value: instrument },
        { label: 'Side', value: side },
        { label: 'Quantity', value: quantity },
        { label: 'Price', value: originalLimitPrice },
        { label: 'Filled', value: sumQuantity(fills) || '0' },
        { label: 'Average Price', value: averagePrice },
      ]);

      setSecondInfoCard([
        { label: 'Id', value: id },
        { label: 'Account', value: account },
        { label: 'Broker url', value: brokerUrl },
        { label: 'Module', value: module },
      ]);

      setThirdInfoCard([
        { label: 'Account name', value: brokerAccount?.accountName },
        { label: 'Gateway name', value: brokerAccount?.gatewayName },
        {
          label: 'Provider name',
          value: dataOrder?.brokerAccount?.providerName,
        },
      ]);
    }
  }, [dataOrder]);

  return { dataOrder, isLoading, firstInfoCard, secondInfoCard, thirdInfoCard };
}
