import { keyframes, styled } from 'react-ui-kit-exante';

const speed = '1.5s';

const tetrominoOne = keyframes`
  0%,
  40% {
    /* compose logo */ /* 1 on 3 */ /* L-shape */
    transform: translate(0, 0);
  }
  50% {
    /* pre-box */
    transform: translate(23px, -12.75px);
  }
  60%,
  100% {
    /* box */ /* compose logo */
    transform: translate(46px, 0);
  }
`;

const tetrominoTwo = keyframes`
  0%, 20% {
    /* compose logo *//* 1 on 3 */
    transform: translate(46px, 0);
  } 40%, 100% {
      /* L-shape *//* box *//* compose logo */
      transform: translate(69px, 12.75px);
    }
`;

const tetrominoThree = keyframes`
  0% {
    /* compose logo */
    transform: translate(69px, 12.75px);
  } 20%, 60% {
      /* 1 on 3 *//* L-shape *//* box */
      transform: translate(46px, 25.5px);
    } 90%, 100% {
        /* compose logo */
        transform: translate(23px, 12.75px);
      }
`;

const tetrominoFour = keyframes`
  0%, 60% {
    /* compose logo *//* 1 on 3 *//* L-shape *//* box */
    transform: translate(23px, 12.75px);
  } 90%, 100% {
      /* compose logo */
      transform: translate(0, 0);
    }
`;

export const Tetrominos = styled('div')(() => ({
  marginBottom: '50px',
  transform: 'translate(-55px, -46px)',
}));

export const BoxOne = styled('div')(() => ({
  animation: `${tetrominoOne} ${speed} ease-out infinite`,
}));

export const BoxTwo = styled('div')(() => ({
  animation: `${tetrominoTwo} ${speed} ease-out infinite`,
}));

export const BoxThree = styled('div')(() => ({
  animation: `${tetrominoThree} ${speed} ease-out infinite`,
  zIndex: '2',
}));

export const BoxFour = styled('div')(() => ({
  animation: `${tetrominoFour} ${speed} ease-out infinite`,
}));

export const TetrominoOne = styled(BoxOne)(({ theme }) => ({
  width: '46px',
  height: '55px',
  position: 'absolute',
  transition: 'all ease .3s',
  background: `url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23${theme?.palette?.success?.main?.replace(
    '#',
    '',
  )}" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23${theme?.color?.bg?.primary?.replace(
    '#',
    '',
  )}" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center`,
}));

export const TetrominoTwo = styled(BoxTwo)(({ theme }) => ({
  width: '46px',
  height: '55px',
  position: 'absolute',
  transition: 'all ease .3s',
  background: `url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23${theme?.palette?.success?.main?.replace(
    '#',
    '',
  )}" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23${theme?.color?.bg?.primary?.replace(
    '#',
    '',
  )}" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center`,
}));

export const TetrominoThree = styled(BoxThree)(({ theme }) => ({
  width: '46px',
  height: '55px',
  position: 'absolute',
  transition: 'all ease .3s',
  background: `url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23${theme?.palette?.success?.main?.replace(
    '#',
    '',
  )}" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23${theme?.color?.bg?.primary?.replace(
    '#',
    '',
  )}" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center`,
}));

export const TetrominoFour = styled(BoxFour)(({ theme }) => ({
  width: '46px',
  height: '55px',
  position: 'absolute',
  transition: 'all ease .3s',
  background: `url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23${theme?.palette?.success?.main?.replace(
    '#',
    '',
  )}" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23${theme?.color?.bg?.primary?.replace(
    '#',
    '',
  )}" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center`,
}));
