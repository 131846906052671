import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { orderManagementService } from 'api';
import { OrderManagementDataContext } from '~/pages/OrderManagement/OrderManagementProvider';
import {
  ActionNames,
  ExtraDestinationRoute,
  MethodNames,
  TypesOrder,
} from '~/pages/OrderManagement/constants';
import { REPORTS } from '~/routes';
import { PostOrdersBodyProps } from '~/types/orderManagement';

interface UsePostOrdersProps {
  baeRoute?: string;
  brokerUrl?: string;
  destinationRoute: string;
  firstSelect: string;
  jmxComment?: string;
  secondSelect: string;
}

export function usePostOrders({
  baeRoute,
  brokerUrl,
  destinationRoute,
  firstSelect,
  jmxComment,
  secondSelect,
}: UsePostOrdersProps) {
  const {
    selectedOrder,
    tableFilters,
    isSelectAll,
    dataActionsTable,
    requestId,
  } = useContext(OrderManagementDataContext);
  const navigate = useNavigate();

  const redirectToReportInfoPage = (actionId: string) => {
    if (actionId) {
      navigate(`/${REPORTS}/${actionId}`);
    }
  };

  const preparePostData = () => {
    const methodName = () => {
      if (firstSelect === ActionNames.ReplaceOnNextRoute) {
        return MethodNames.PlaceOnNextRoute;
      }
      if (firstSelect === ActionNames.ReplaceOnInitialRoute) {
        return MethodNames.PlaceOnInitialRoute;
      }
      if (
        firstSelect === ActionNames.CancelViaBrokerProtocol &&
        secondSelect === ActionNames.Reset
      ) {
        return MethodNames.Cancel;
      }
      if (firstSelect === ActionNames.ReplaceOnRoute) {
        return MethodNames.PlaceOnRoute;
      }
      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        return MethodNames.Replace;
      }

      return MethodNames.MarkCancelled;
    };

    const method = methodName();

    const getOrderIdList = () => selectedOrder.map(({ values }) => values?.id);

    const getParamsForSomeOrders = () => {
      const body: PostOrdersBodyProps = {
        requestId,
        orderIdList: getOrderIdList(),
      };

      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        body.cancelStrategy =
          firstSelect === ActionNames.CancelViaBrokerProtocol
            ? TypesOrder.Broker
            : TypesOrder.Internal;
      }

      if (
        destinationRoute &&
        destinationRoute !== ExtraDestinationRoute.Bae &&
        destinationRoute !== ExtraDestinationRoute.Same
      ) {
        body.route = destinationRoute;
      }

      if (brokerUrl) {
        body.brokerUrl = brokerUrl;
      }

      if (
        baeRoute &&
        baeRoute !== ExtraDestinationRoute.Bae &&
        baeRoute !== ExtraDestinationRoute.Same
      ) {
        body.route = baeRoute;
      }

      if (firstSelect === ActionNames.CancelViaJMX && jmxComment?.trim()) {
        body.comment = jmxComment;
      }

      return body;
    };

    const getParamsForAllOrders = () => {
      const body: PostOrdersBodyProps = {
        requestId,
        excludeOrderIdList: getOrderIdList(),
        ordersFilter: {
          ...tableFilters,
          skip: 0,
          limit: dataActionsTable?.pagination?.total || 0,
        },
      };

      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        body.cancelStrategy =
          firstSelect === ActionNames.CancelViaBrokerProtocol
            ? TypesOrder.Broker
            : TypesOrder.Internal;
      }

      if (
        destinationRoute &&
        destinationRoute !== ExtraDestinationRoute.Bae &&
        destinationRoute !== ExtraDestinationRoute.Same
      ) {
        body.route = destinationRoute;
      }

      if (brokerUrl) {
        body.brokerUrl = brokerUrl;
      }

      if (
        baeRoute &&
        baeRoute !== ExtraDestinationRoute.Bae &&
        baeRoute !== ExtraDestinationRoute.Same
      ) {
        body.route = baeRoute;
      }

      if (firstSelect === ActionNames.CancelViaJMX && jmxComment?.trim()) {
        body.comment = jmxComment;
      }

      return body;
    };

    return {
      method,
      body: isSelectAll ? getParamsForAllOrders() : getParamsForSomeOrders(),
    };
  };

  const postOrders = async () => {
    const isBrokerOrders =
      firstSelect === ActionNames.CancelViaBrokerProtocol ||
      (firstSelect === ActionNames.CancelViaJMX &&
        secondSelect === ActionNames.PlaceViaBrokerProtocol);

    if (isBrokerOrders) {
      try {
        const { actionId } = await orderManagementService.postBrokerOrders(
          preparePostData(),
        );
        redirectToReportInfoPage(actionId);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const { actionId } = await orderManagementService.postInternalOrders(
          preparePostData(),
        );
        redirectToReportInfoPage(actionId);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return { postOrders };
}
