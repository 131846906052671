import { Notification } from 'react-ui-kit-exante';

import { Notifications } from '~/constants';
import { Logger } from '~/types/logger';
import {
  FetchReportsListProps,
  OrdersParamsProps,
  PostOrdersParamsProps,
  PostSingleOrderParamsProps,
} from '~/types/orderManagement';
import { errorProcessing } from '~/utils';

import { OrderManagementRepository } from './orderManagement.repository';

export class OrderManagementService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  public async getOrders(params: OrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.fetchOrderManagement(
        params,
      );

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async postInternalOrders(params: PostOrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifyInternalOrders(
        params,
      );
      Notification.success(Notifications.Success);
      return data;
    } catch (error) {
      this.logger.error(error);
      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async postBrokerOrders(params: PostOrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifyBrokerOrders(
        params,
      );
      Notification.success(Notifications.Success);
      return data;
    } catch (error) {
      this.logger.error(error);
      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getModules() {
    try {
      const { data } = await OrderManagementRepository.fetchModules();

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getOrderReportInfo(id: string) {
    try {
      const { data } = await OrderManagementRepository.fetchOrderReportInfo(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getOrderReportStepOneList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportStepOneList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getOrderReportStepTwoList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportStepTwoList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getOrderReportSuccessList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportSuccessList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getReportsList(params: FetchReportsListProps) {
    try {
      const { data } = await OrderManagementRepository.fetchReportsList(params);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error(Notifications.Error);

      return null;
    }
  }

  public async getBrokerUrls() {
    try {
      const { data } = await OrderManagementRepository.fetchBrokerUrls();

      return data;
    } catch (error) {
      this.logger.error(error);

      return null;
    }
  }

  public async getRoutes(instrument: string) {
    try {
      const { data } = await OrderManagementRepository.fetchRoutes(instrument);

      return data;
    } catch (error) {
      this.logger.error(error);

      return null;
    }
  }

  public async getOrder(id: string) {
    try {
      const { data } = await OrderManagementRepository.fetchOrder(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      return null;
    }
  }

  public async postSingleBaeOrder(params: PostSingleOrderParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifySingleBaeOrder(
        params,
      );
      Notification.success(Notifications.Success);
      return data;
    } catch (error: any) {
      this.logger.error(error);
      Notification.error(errorProcessing(error));

      return error;
    }
  }

  public async postSingleGwOrder(params: PostSingleOrderParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifySingleGwOrder(
        params,
      );
      Notification.success(Notifications.Success);
      return data;
    } catch (error: any) {
      this.logger.error(error);
      Notification.error(errorProcessing(error));

      return error;
    }
  }

  public async postSingleCancel(params: PostSingleOrderParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifySingleCancel(
        params,
      );
      Notification.success(Notifications.Success);
      return data;
    } catch (error: any) {
      this.logger.error(error);
      Notification.error(errorProcessing(error));

      return error;
    }
  }
}
