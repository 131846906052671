import { styled, Tabs } from 'react-ui-kit-exante';

export const TabsBlock = styled('div')(({ theme }) => ({
  background: theme?.color?.bg?.primary,
}));

export const StyledTabs = styled(Tabs)(() => ({
  paddingLeft: '24px',
}));

export const LoaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '70vh',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const StyledHint = styled('p')(({ theme }) => ({
  color: theme?.color.typo?.primary,
  font: '400 20px / 1 Inter, sans-serif',
}));
