import { styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 24px 0',
  background: theme?.color.bg.primary,
}));

export const Fields = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
});

export const Field = styled('div')({ width: '32% ', marginRight: '24px' });

export const ExtraField = styled('div')({
  width: '31.5% ',
  marginTop: '24px',
  marginRight: '24px',
});

export const AllFields = styled('div')({ width: '90% ' });
