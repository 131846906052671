import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';

import { getAccessTokenFromCookies } from '../session';

import { getAPIHost } from './getAPIHost';
import { getDefaultHeaders, paramsSerializer } from './helpers';
import { IOptionsArguments } from './types';

export interface IRequestArguments {
  url: string;
  method?: Method;
  headers?: AxiosRequestHeaders;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiRequest<T = any>(
  args: IRequestArguments,
  options?: Partial<IOptionsArguments>,
): Promise<AxiosResponse<T>> {
  const { url: originalUrl, method = 'GET', data, headers, params } = args;

  const baseUrl = getAPIHost();
  const fullApiUrl = /^(https?:\/\/)/.test(originalUrl)
    ? originalUrl
    : `${baseUrl}${originalUrl}`;

  const config: AxiosRequestConfig = {
    url: fullApiUrl,
    method,
    data: method !== 'GET' ? data : null,
    headers: headers || getDefaultHeaders(getAccessTokenFromCookies()),
    params,
    paramsSerializer,
    ...options,
  };

  return axios(config);
}
