import { ReportInfoProvider } from './ReportInfoProvider';
import { InfoWithTable } from './components/InfoWithTable';

export const ReportInfo = () => {
  return (
    <ReportInfoProvider>
      <InfoWithTable />
    </ReportInfoProvider>
  );
};
