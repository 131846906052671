import { FC } from 'react';

import { ReactComponent as Canceled } from '~/assets/icons/canceled.svg';
import { ReactComponent as Failed } from '~/assets/icons/failed.svg';
import { ReactComponent as Pending } from '~/assets/icons/pending.svg';
import { ReactComponent as Processed } from '~/assets/icons/processed.svg';
import { ReactComponent as Spinner } from '~/assets/icons/spinner.svg';
import { ReactComponent as Working } from '~/assets/icons/working.svg';
import { Statuses } from '~/pages/OrderManagement/constants';

import { StyledStatusCell } from './StatusCell.styled';

export const getStatusIcon = (type: string) => {
  if (type === Statuses.Pending) {
    return <Pending />;
  }
  if (type === Statuses.Working) {
    return <Working />;
  }
  if (type === Statuses.Canceled) {
    return <Canceled />;
  }
  if (type === Statuses.Failed) {
    return <Failed />;
  }
  if (type === Statuses.Requested) {
    return <Spinner />;
  }
  if (
    type === Statuses.Processed ||
    type === Statuses.Registered ||
    type === Statuses.Placing
  ) {
    return <Processed />;
  }
  return '–';
};

export const StatusCell = ({ type }: { type: string }) => (
  <StyledStatusCell>
    {getStatusIcon(type)}
    {type}
  </StyledStatusCell>
);
