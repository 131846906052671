import { ReactNode } from 'react';

import { Wrapper } from './ApplicationWrapper.styled';

interface IWrapperProps {
  children: ReactNode;
}

export const ApplicationWrapper = ({ children }: IWrapperProps) => {
  return <Wrapper>{children}</Wrapper>;
};
