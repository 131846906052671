import { FilterValue } from 'react-ui-kit-exante';

interface ColumnsProps {
  onRemove(col: string): void;
  onFilter(col: string, value: FilterValue): void;
}

export const useReportsColumns = ({ onRemove, onFilter }: ColumnsProps) => {
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'date',
      accessor: 'date',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'dateTime',
    },
    {
      Header: 'First step failed',
      accessor: 'stepOneFailureCount',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Second step failed',
      accessor: 'stepTwoFailureCount',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Fulfilled',
      accessor: 'successCount',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Action 1',
      accessor: 'stepOneName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Action 2',
      accessor: 'stepTwoName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Username',
      accessor: 'username',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
  ];

  return columns;
};
