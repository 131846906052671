import { initResources } from './api';
import { Logger } from './utils';
import { initApp } from './utils/initApp';

const logger = new Logger();

initResources(logger);

window.ORDER_MANAGEMENT_UI = {
  ...(window.ORDER_MANAGEMENT_UI || {}),
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
