export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
export const DEFAULT_EMPTY_PLACEHOLDER = '—';
export const DEFAULT_POOLING_INTERVAL_DATA = 3000; // 3 sec
export const DEFAULT_TABLE_COLUMN_WIDTH = 150;
export const EMPTY_ARRAY: never[] = [];
export const EMPTY_FUNCTION = () => {};
export const EMPTY_OBJECT = {};
export const FETCH_DATA_SIZE = 100;
export const MAX_REQUEST_LIMIT = 1000;
export const NON_BREAKING_SPACE = '\u00A0';
export const NO_DATA_HEIGHT = 'auto';
export const SERVICE_NAME = 'bo';

export const EN_DASH = '–';

export const REGEX_LAST_SEGMENT_IN_PATH = /::[^:]*$/;
export const REGEX_DOUBLE_COLON = /::(?=[^::]*$)/;

export const DEFAULT_SORT_TABLE_BY = EMPTY_ARRAY;
export const EMPTY_INSTRUMENTS_DATA = {
  data: { instruments: [], isFinish: true },
};

export enum Notifications {
  Success = 'Success',
  Error = 'Something went wrong',
}
