export enum SingleOrderActions {
  MarkCancelled = 'Cancel via JMX',
  Cancel = 'Cancel via Broker Protocol',
  PlaceOnInitialRoute = 'Place on Initial Route',
  PlaceOnNextRoute = 'Place on Next Route',
  PlaceOnRoute = 'Place on Route',
  AddFill = 'Add Fill',
  RejectModification = 'Reject Modification',
  EditOrder = 'Edit Order',
  RejectOrder = 'Reject Order',
}

export enum SingleOrderMethodNames {
  MarkCancelled = 'mark-cancelled',
  PlaceOnInitialRoute = 'place-on-initial-route',
  PlaceOnNextRoute = 'place-on-next-route',
  PlaceOnRoute = 'place-on-route',
  AddFill = 'add-fill',
  RejectModification = 'reject-modification',
  EditOrder = 'edit-order',
  RejectOrder = 'reject-order',
}

export const SingleOrderBaeOptions = [
  {
    value: SingleOrderActions.MarkCancelled,
    label: SingleOrderActions.MarkCancelled,
  },
  {
    value: SingleOrderActions.Cancel,
    label: SingleOrderActions.Cancel,
  },
  {
    value: SingleOrderActions.PlaceOnInitialRoute,
    label: SingleOrderActions.PlaceOnInitialRoute,
  },
  {
    value: SingleOrderActions.PlaceOnNextRoute,
    label: SingleOrderActions.PlaceOnNextRoute,
  },
  {
    value: SingleOrderActions.PlaceOnRoute,
    label: SingleOrderActions.PlaceOnRoute,
  },
];

export const SingleOrderGatewayOptions = [
  {
    value: SingleOrderActions.Cancel,
    label: SingleOrderActions.Cancel,
  },
  {
    value: SingleOrderActions.MarkCancelled,
    label: SingleOrderActions.MarkCancelled,
  },
  {
    value: SingleOrderActions.AddFill,
    label: SingleOrderActions.AddFill,
  },
  {
    value: SingleOrderActions.RejectModification,
    label: SingleOrderActions.RejectModification,
  },
  {
    value: SingleOrderActions.EditOrder,
    label: SingleOrderActions.EditOrder,
  },
  {
    value: SingleOrderActions.RejectOrder,
    label: SingleOrderActions.RejectOrder,
  },
];

export const ChooseField = {
  width: '23%',
  margin: '12px 0 38px',
};
