import cn from 'classnames';

import DarkModeButtonStyles from './DarkModeButton.module.css';

interface DarkModeButtonProps {
  onClick: () => void;
}

export const isDarkMode = localStorage.getItem('dark-mode') === 'true';

export const DarkModeButton = ({ onClick }: DarkModeButtonProps) => (
  <input
    type="checkbox"
    onClick={onClick}
    className={cn(
      DarkModeButtonStyles.Toggle,
      !isDarkMode && DarkModeButtonStyles.Moon,
    )}
  />
);
