import { CURRENT_USER_ACCESS_RIGHTS_ENDPOINT } from '~/api/accessRights/endpoints';
import { CurrentUser } from '~/types/api/user';
import { apiRequest } from '~/utils';

export class AccessRightsRepository {
  public static fetchCurrentUserAccessRights() {
    return apiRequest<CurrentUser>({
      url: CURRENT_USER_ACCESS_RIGHTS_ENDPOINT,
    });
  }
}
