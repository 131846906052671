import { useCallback, useContext, useMemo } from 'react';
import { useTableData } from 'react-ui-kit-exante';

import { orderManagementService } from 'api';
import { RefreshButton } from '~/components/RefreshButton';
import { OrderManagementDataContext } from '~/pages/OrderManagement/OrderManagementProvider';
import { OrdersParamsProps, OrdersProps } from '~/types/orderManagement';
import { getTableId, calculateCountOfPages } from '~/utils';

export function useOrdersTableData(
  refetch: boolean,
  setRefetch: (refetch: boolean) => void,
  resetSettings: () => void,
) {
  const { setTableFilters } = useContext(OrderManagementDataContext);

  const getOrders = useCallback(
    (values: { params: OrdersParamsProps }) => {
      setTableFilters(values.params);
      return orderManagementService.getOrders(values.params);
    },
    [refetch],
  );

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: getOrders,
      },
      tableId: getTableId('OrdersTable'),
      saveViewParamsAfterLeave: true,
    }),
    [getOrders],
  );

  const {
    data,
    page,
    skip,
    limit,
    setPage,
    setLimit,
    isLoading,
    setFilter,
    setSorting,
    resetFilters,
    removeFilter,
    filters: filtersTable,
  } = useTableData<OrdersProps | null>(tableArgs);

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: (values: string[]) => {
        resetSettings();
        resetFilters(values);
      },
      filters: filtersTable,
      manualFilters: true,
    }),
    [resetFilters, filtersTable],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'refresh',
        component: <RefreshButton onRefresh={() => setRefetch(!refetch)} />,
      },
    ],
    [refetch],
  );

  return {
    data,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
    filteringProps,
    additionalActions,
    serverPaginationProps,
  };
}
