import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { IRow } from 'react-ui-kit-exante';

import { accessRightsService } from '~/api';
import { BAE } from '~/pages/OrderManagement/constants';
import { useModulesData } from '~/pages/OrderManagement/hooks/useModulesData';
import { ContextOrdersProps } from '~/pages/OrderManagement/types';
import { ChildrenProp } from '~/types/common';
import { OrderProps, OrdersProps } from '~/types/orderManagement';
import { createUUID } from '~/utils';

export const OrderManagementDataContext = createContext<ContextOrdersProps>({
  isBae: false,
  dataActionsTable: { data: [], pagination: { total: 0 } },
  dataModules: [],
  selectedOrder: [],
  defaultModule: '',
  handleSelectOrder: () => [],
  setIsSelectAll: () => {},
  isSelectAll: false,
  tableFilters: { module: [] },
  setTableFilters: () => {},
  setDataActionsTable: () => {},
  requestId: '',
  setRequestId: () => {},
  username: '',
});

export const OrderManagementProvider = ({ children }: ChildrenProp) => {
  const { dataModules, defaultModule } = useModulesData();
  const [username, setUsername] = useState('');
  const [requestId, setRequestId] = useState('');
  const [dataActionsTable, setDataActionsTable] = useState<OrdersProps | null>(
    null,
  );
  const [selectedOrder, setSelectedOrder] = useState<IRow<OrderProps>[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [tableFilters, setTableFilters] = useState<{
    module?: string | string[];
  } | null>(null);

  const getUserData = useCallback(async () => {
    const data = await accessRightsService.getCurrentUserAccessRights();
    if (data) {
      setUsername(data?.username);
    }
  }, []);

  const filterByModule = tableFilters?.module;

  const checkModulesFilter = () => {
    if (filterByModule) {
      if (typeof filterByModule === 'string') {
        return filterByModule?.includes(BAE);
      }
      if (Array.isArray(filterByModule)) {
        return filterByModule.every((item) => item.includes(BAE));
      }
    }

    return null;
  };
  const isBae = checkModulesFilter();

  const handleSelectOrder = useCallback(
    (value: IRow<OrderProps> | IRow<OrderProps>[]) => {
      setSelectedOrder((prev) => {
        if (Array.isArray(value)) {
          return value;
        }

        const id = value.values.id;

        if (prev.some((item) => item.values.id === id)) {
          return prev.filter((row) => {
            return row.values.id !== id;
          });
        }

        return [...prev, value];
      });
    },
    [setSelectedOrder],
  );

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (!requestId) {
      setRequestId(createUUID());
    }
  }, [requestId]);

  const providerValue = useMemo(
    () => ({
      isBae,
      selectedOrder,
      handleSelectOrder,
      dataModules,
      defaultModule,
      setIsSelectAll,
      isSelectAll,
      tableFilters,
      setTableFilters,
      dataActionsTable,
      setDataActionsTable,
      requestId,
      setRequestId,
      username,
    }),
    [
      isBae,
      selectedOrder,
      handleSelectOrder,
      dataModules,
      defaultModule,
      setIsSelectAll,
      isSelectAll,
      tableFilters,
      setTableFilters,
      dataActionsTable,
      setDataActionsTable,
      requestId,
      setRequestId,
      username,
    ],
  );

  return (
    <OrderManagementDataContext.Provider value={providerValue}>
      {children}
    </OrderManagementDataContext.Provider>
  );
};
