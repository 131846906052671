import React from 'react';
import { createRoot } from 'react-dom/client';

import { Application } from '~/pages/Application';
import { ComposedThemeProvider } from '~/theme/ComposedThemeProvider';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  window.ORDER_MANAGEMENT_UI = {
    ...(window.ORDER_MANAGEMENT_UI || {}),
    root,
  };

  root.render(
    <ComposedThemeProvider>
      <Application />
    </ComposedThemeProvider>,
  );
};
