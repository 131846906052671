export const BottomArrowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 16.5,4.5 L 12.0,9.0 L 7.5,4.5"
        stroke="#818C99"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0 6)"
      />
    </svg>
  );
};
