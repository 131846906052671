import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-ui-kit-exante';

import { REPORTS } from '~/routes';
import { getTableId } from '~/utils';

import { useReportsColumns, useReportsTableData } from '../../hooks';

import { DEFAULT_SORTING, DISPLAYED_COLUMNS_KEYS } from './constants';

export const ReportsTables = () => {
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    setFilter,
    removeFilter,
    filteringProps,
    serverPaginationProps,
  } = useReportsTableData();

  const handleRowClick = useCallback(
    ({ id }: { id?: string }) => {
      if (id) {
        navigate(`/${REPORTS}/${id}`, { state: { activeReportsTab: true } });
      }
    },
    [navigate],
  );

  const columns = useReportsColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  return (
    <Table
      hasFilters
      isFlexLayout
      showTableInfo
      hasPagination
      pageSize={20}
      isPinnedHeader
      columns={columns}
      isLoading={isLoading}
      data={data?.data || []}
      saveViewParamsAfterLeave
      filteringProps={filteringProps}
      defaultSortBy={DEFAULT_SORTING}
      handleRowClick={handleRowClick}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      serverPaginationProps={serverPaginationProps}
      tableId={getTableId('ReportsTable')}
    />
  );
};
