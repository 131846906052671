enum TableId {
  OrdersTable = 'orders-table',
  ReportsTable = 'reports-table',
  ReportInfoTable = 'report-info-table',
}

export const PROJECT_PREFIX = 'order-management';

export const getTableId = (tableName: keyof typeof TableId) => {
  return `${PROJECT_PREFIX}-${TableId[tableName]}`;
};
