import { styled, Table } from 'react-ui-kit-exante';

import { OrderProps } from '~/types/orderManagement';

export const TableContainer = styled(Table<OrderProps>)(() => ({
  '& .FilterRow .MuiInputBase-root input.MuiAutocomplete-input': {
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
  },
  '& .FilterRow .MuiInputBase-root .MuiAutocomplete-endAdornment': {
    top: 'auto !important',
  },
}));
