import { styled } from 'react-ui-kit-exante';

export const WrapperCard = styled('div')(() => ({
  width: '30%',
  minWidth: '320px',
}));

export const ItemInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
  marginRight: '40px',
}));

export const Label = styled('div')(({ theme }) => ({
  font: '400 13px Inter, sans-serif',
  color: theme?.color?.typo?.secondary,
}));

export const Value = styled('div')(({ theme }) => ({
  font: '600 13px Inter, sans-serif',
  color: theme?.color?.typo?.secondary,
}));
