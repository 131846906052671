import { Logger } from '~/types/logger';

import { versionMapper } from './application.mapper';
import { ApplicationRepository } from './application.repository';

export class ApplicationService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  private getUiVersion() {
    return process?.env?.REACT_APP_VERSION || null;
  }

  public async getAppVersion() {
    let ui: string | null = null;

    ui = this.getUiVersion();

    return versionMapper(ui);
  }
}
