import { Notifications } from '~/constants';

export const errorProcessing = (error: any) => {
  const errorData = error?.response?.data;
  const errorMessage = `${
    (errorData?.error,
    Array.isArray(errorData?.message)
      ? errorData?.message[0]
      : errorData?.message)
  }`;

  return errorData ? errorMessage : Notifications.Error;
};
