import { OptionProps } from '~/types/common';

import { ItemInfo, Label, Value, WrapperCard } from './InfoCard.styled';

interface InfoCardProps {
  data: OptionProps[];
}

export const InfoCard = ({ data }: InfoCardProps) => (
  <WrapperCard>
    {data?.map(({ value, label }) => {
      return (
        <ItemInfo key={label}>
          <Label>{label}</Label>
          <Value>{value || '-'}</Value>
        </ItemInfo>
      );
    })}
  </WrapperCard>
);
