import { useContext, useEffect, useState } from 'react';
import { Loader, Tab, Table } from 'react-ui-kit-exante';

import { TetrisLoader } from 'components/TetrisLoader';
import { StyledTab } from '~/pages/OrderManagement/components/OrderManagementTables/OrderManagementTables.styled';
import { getTableId } from '~/utils';

import { ReportInfoContext } from '../../ReportInfoProvider';
import { COLUMNS, NameTabs, STATUS_FINISH } from '../../constants';
import { useReportTables } from '../../hooks';
import { HeadInfo } from '../HeadInfo';
import { LabelTab } from '../LabelTab';

import {
  LoaderWrapper,
  StyledHint,
  StyledTabs,
  TabsBlock,
} from './InfoWithTable.styled';

export const InfoWithTable = () => {
  const { dataReportInfo } = useContext(ReportInfoContext);
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const { dataTable, isLoading } = useReportTables(activeTab);

  useEffect(() => {
    if (dataReportInfo) {
      setActiveTab(
        dataReportInfo?.stepTwoName ? NameTabs.Fulfilled : NameTabs.NotPlaced,
      );
    }
  }, [dataReportInfo]);

  return typeof activeTab === 'number' ? (
    <>
      <HeadInfo />
      <TabsBlock>
        <StyledTabs
          value={activeTab}
          onChange={(e, value) => {
            setActiveTab(value);
          }}
        >
          <Tab
            style={StyledTab}
            label={
              <LabelTab
                text="Not Сancelled"
                activeTab={activeTab}
                numberTab={NameTabs.NotСancelled}
                value={dataReportInfo?.stepOneFailureCount}
              />
            }
          />
          {dataReportInfo?.stepTwoName && (
            <Tab
              style={StyledTab}
              label={
                <LabelTab
                  text="Not Placed"
                  activeTab={activeTab}
                  numberTab={NameTabs.NotPlaced}
                  value={dataReportInfo?.stepTwoFailureCount}
                />
              }
            />
          )}
          <Tab
            style={StyledTab}
            label={
              <LabelTab
                text="Fulfilled"
                activeTab={activeTab}
                numberTab={
                  dataReportInfo?.stepTwoName
                    ? NameTabs.Fulfilled
                    : NameTabs.NotPlaced
                }
                value={dataReportInfo?.successCount}
              />
            }
          />
        </StyledTabs>
        {dataReportInfo?.status === STATUS_FINISH ? (
          <Table
            hasFilters
            isFlexLayout
            showTableInfo
            hasPagination
            pageSize={20}
            isPinnedHeader
            data={dataTable || []}
            columns={COLUMNS}
            isLoading={isLoading}
            saveViewParamsAfterLeave
            tableId={getTableId('ReportInfoTable')}
          />
        ) : (
          <LoaderWrapper>
            <TetrisLoader />
            <StyledHint>
              The action report is being processed. It may take some time...
            </StyledHint>
          </LoaderWrapper>
        )}
      </TabsBlock>
    </>
  ) : (
    <Loader size="l" isCentered />
  );
};
