import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { orderManagementService } from '~/api';
import {
  SingleOrderActions,
  SingleOrderMethodNames,
} from '~/pages/SingleOrder/constants';
import { ORDERS } from '~/routes';
import { PostSingleOrderBodyProps } from '~/types/orderManagement';

interface UsePostOrderSingleProps {
  route?: string;
  chooseSelect: string;
  comment?: string;
  isBaeOrder: boolean | null;
  modificationId?: string;
  currentFilledQuantity?: string;
  fillQuantity?: string;
  exchangeTradeId?: string;
  fillPrice?: string;
  mICCodeOfExecutor?: string;
  mICCodeOrXOFFCode?: string;
  fillTime?: Date | null;
  reason?: string;
  quantity?: string;
  limitPrice?: string;
  stopPrice?: string;
  onCloseModal: () => void;
}

export const usePostOrderSingle = ({
  route,
  reason,
  comment,
  fillTime,
  quantity,
  stopPrice,
  fillPrice,
  isBaeOrder,
  limitPrice,
  chooseSelect,
  fillQuantity,
  onCloseModal,
  modificationId,
  exchangeTradeId,
  mICCodeOfExecutor,
  mICCodeOrXOFFCode,
  currentFilledQuantity,
}: UsePostOrderSingleProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const preparePostData = () => {
    const methodName = () => {
      if (chooseSelect === SingleOrderActions.MarkCancelled) {
        return SingleOrderMethodNames.MarkCancelled;
      }
      if (chooseSelect === SingleOrderActions.PlaceOnInitialRoute) {
        return SingleOrderMethodNames.PlaceOnInitialRoute;
      }
      if (chooseSelect === SingleOrderActions.PlaceOnNextRoute) {
        return SingleOrderMethodNames.PlaceOnNextRoute;
      }
      if (chooseSelect === SingleOrderActions.PlaceOnRoute) {
        return SingleOrderMethodNames.PlaceOnRoute;
      }
      if (chooseSelect === SingleOrderActions.AddFill) {
        return SingleOrderMethodNames.AddFill;
      }
      if (chooseSelect === SingleOrderActions.RejectModification) {
        return SingleOrderMethodNames.RejectModification;
      }
      if (chooseSelect === SingleOrderActions.EditOrder) {
        return SingleOrderMethodNames.EditOrder;
      }
      if (chooseSelect === SingleOrderActions.RejectOrder) {
        return SingleOrderMethodNames.RejectOrder;
      }

      return '';
    };

    const method = methodName();

    const getParams = () => {
      const body: PostSingleOrderBodyProps = {
        orderId: id,
      };

      if (chooseSelect === SingleOrderActions.MarkCancelled) {
        body.comment = comment;
      }

      if (chooseSelect === SingleOrderActions.PlaceOnRoute) {
        body.route = route;
      }
      if (chooseSelect === SingleOrderActions.AddFill) {
        body.comment = comment;
        body.fillTime = fillTime;
        body.fillPrice = fillPrice;
        body.fillQuantity = fillQuantity;
        body.exchangeTradeId = exchangeTradeId;
        body.MICCodeOfExecutor = mICCodeOfExecutor;
        body.MICCodeOrXOFFCode = mICCodeOrXOFFCode;
        body.currentFilledQuantity = currentFilledQuantity;
      }
      if (chooseSelect === SingleOrderActions.RejectModification) {
        body.reason = reason;
        body.modificationId = modificationId;
      }
      if (chooseSelect === SingleOrderActions.EditOrder) {
        body.quantity = quantity;
        body.stopPrice = stopPrice;
        body.limitPrice = limitPrice;
      }
      if (chooseSelect === SingleOrderActions.RejectOrder) {
        body.reason = reason;
      }

      return body;
    };

    return {
      method,
      body: getParams(),
    };
  };

  const postSingleOrder = async () => {
    setIsLoading(true);
    const redirectToOrdersPage = (isErrorInfo: boolean) => {
      if (!isErrorInfo) {
        navigate(`/${ORDERS}`);
      }

      onCloseModal();
    };

    if (chooseSelect !== SingleOrderActions.Cancel) {
      if (isBaeOrder) {
        const errorInfo = await orderManagementService.postSingleBaeOrder(
          preparePostData(),
        );

        setIsLoading(false);
        redirectToOrdersPage(errorInfo);
      } else {
        const errorInfo = await orderManagementService.postSingleGwOrder(
          preparePostData(),
        );

        setIsLoading(false);
        redirectToOrdersPage(errorInfo);
      }
    } else {
      const errorInfo = await orderManagementService.postSingleCancel(
        preparePostData(),
      );

      setIsLoading(false);
      redirectToOrdersPage(errorInfo);
    }
  };

  return { postSingleOrder, isLoading };
};
