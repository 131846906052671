import { merge } from 'lodash';
import { useMemo } from 'react';
import {
  createTheme,
  ThemeProvider,
  darkTheme,
  defaultTheme,
} from 'react-ui-kit-exante';

import { isDarkMode } from '~/components/DarkModeButton';
import { ChildrenProp } from '~/types/common';

import { theme } from './index';

export const ComposedThemeProvider = ({ children }: ChildrenProp) => {
  const brandingTheme = window.ORDER_MANAGEMENT_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return isDarkMode ? defaultTheme : darkTheme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
